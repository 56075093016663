export const formatDate = (
  dateFrom: string,
  dateTo?: string,
  withYear?: boolean,
): string => {
  const startDate: Date = new Date(dateFrom);

  const formatWithCustomYear = (date: Date) => {
    const dayMonth = date.toLocaleDateString('ru-RU', {
      month: 'long',
      day: 'numeric',
    });
    const year = date.getFullYear();
    return withYear ? `${dayMonth} ${year}` : dayMonth;
  };

  if (!dateTo) {
    return formatWithCustomYear(startDate);
  } else {
    const endDate = new Date(dateTo);

    if (startDate.getMonth() === endDate.getMonth()) {
      const startDay = startDate.getDate();
      const endDayMonth = formatWithCustomYear(endDate);
      if (startDay === endDate.getDate()) return endDayMonth;

      return `${startDay} - ${endDayMonth}`;
    } else {
      const startDayMonth = formatWithCustomYear(startDate);
      const endDayMonth = formatWithCustomYear(endDate);
      return `${startDayMonth} - ${endDayMonth}`;
    }
  }
};
