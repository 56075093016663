import cn from 'classnames';
import styles from './ArrowButton.module.scss';
import { Sizes, Variants } from '@/types';

type ArrowButtonProps = {
  className?: string;
  onClick?: () => void;
  width?: number;
  height?: number;
  color?: string;
  disabled?: boolean;
  variant: Variants | Sizes;
};

export function ArrowButton({
  className,
  onClick,
  width = 24,
  height = 24,
  disabled,
  variant,
}: ArrowButtonProps) {
  const buttonOutlineClassName = cn(
    styles.button,
    styles.outline,
    {
      [styles.disabled]: disabled,
    },
    className,
  );

  const buttonFilledClassName = cn(
    styles.button,
    'show',
    styles.filled,
    {
      [styles.disabled]: disabled,
    },
    className,
  );

  const buttonSmallClassName = cn(
    styles.button,
    styles.filled,
    styles.small,
    {
      [styles.disabled]: disabled,
    },
    className,
  );

  const buttonGhostClassName = cn(
    styles.button,
    styles.ghost,
    {
      [styles.disabled]: disabled,
    },
    className,
  );

  if (variant === Variants.Outline) {
    return (
      <div className={buttonOutlineClassName} onClick={onClick}>
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
        >
          <path
            d="M5 12H19"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 19L19 12L12 5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  if (variant === Variants.Filled) {
    return (
      <div className={buttonFilledClassName} onClick={onClick}>
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
        >
          <path
            d="M5 12H19"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 19L19 12L12 5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  if (variant === Variants.Ghost) {
    return (
      <div className={buttonGhostClassName} onClick={onClick}>
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
        >
          <path
            d="M5 12H19"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 19L19 12L12 5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  if (variant === Sizes.Small) {
    return (
      <div className={buttonSmallClassName} onClick={onClick}>
        <svg
          width={20}
          height={20}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 16L16 12L12 8"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 12L16 12"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  return null;
}
