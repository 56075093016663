import cn from 'classnames';
import styles from './Tag.module.scss';

type TagProps = {
  text: string;
  className?: string;
};

export function Tag({ text, className }: TagProps) {
  const tagClassName = cn(styles.tag, className);

  return <div className={tagClassName}>{text}</div>;
}
