import cn from 'classnames';
import styles from './Backdrop.module.scss';

type BackdropProps = {
  height?: number | string;
  className?: string;
};

export function Backdrop({ className, height }: BackdropProps) {
  const backdropClassName = cn(styles.backdrop, className);

  return <div className={backdropClassName} style={{ height }} />;
}
